<div
	fxLayout
	fxLayoutAlign="center center"
	style="height: 100%"
	[ngStyle]="{ 'padding-left': defaultSidePadding, 'padding-right': defaultSidePadding }"
>
	<div class="terminal-window" [ngStyle]="{ height: defaultTerminalHeight }">
		<header>
			<div class="button green"></div>
			<div class="button yellow"></div>
			<div class="button red"></div>
		</header>

		<section class="terminal">
			<div style="height: 100%; width: 100%; overflow: hidden" id="terminal"></div>
		</section>
	</div>
</div>
