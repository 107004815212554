<div fxLayout fxLayoutAlign="center center" style="padding-left: 5%; padding-right: 5%; height: 100%;">
	<div class="terminal-window">
		<header>
			<div class="button green"></div>
			<div class="button yellow"></div>
			<div class="button red"></div>
		</header>

		<section class="terminal">
			<div style="height: 100%; width: 100%; overflow: hidden;" id="terminal"></div>
		</section>
	</div>
</div>
