export const environment = {
	production: false,
	firebaseConfig: {
		apiKey: 'AIzaSyCtmOPlPNWywTKQ5ZX3UHTq3YaOitzq9TU',
		authDomain: 'byhuy-d37ff.firebaseapp.com',
		databaseURL: 'https://byhuy-d37ff.firebaseio.com',
		projectId: 'byhuy-d37ff',
		storageBucket: 'byhuy-d37ff.appspot.com',
		messagingSenderId: '926809690519',
		appId: '1:926809690519:web:bc8eb2000cd0f9d44334d2',
		measurementId: 'G-CTRD8G6QBG',
	},
	notificationEndPoint: 'https://us-central1-byhuy-d37ff.cloudfunctions.net/notifications',
	AIEndPoint: 'https://us-central1-byhuy-d37ff.cloudfunctions.net/ai',
	AIName: 'Weezing',
	huyUserId: 'lgfaf34iwMWLZoI8SJi8M4OfQUA3',
	huyUserName: 'Huy',
};
